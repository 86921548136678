<template>
    <div class="messages-container">
        <ul>
            <li class="message-element" v-for="message in messagesStore.messages" :key="message.id">
                <PrivateMessage :message=message></PrivateMessage>
            </li>
        </ul>
    </div>
</template>

<script>
    import { useCurrentFriendChatStore } from '@/stores/CurrentFriendChat';
    import { useUserStore } from '@/stores/UserStore';
    import { useMessagesStore } from '@/stores/MessagesStore';
    import PrivateMessage from './PrivateMessage.vue'
    export default {
        setup(){
            const currentFriendChatStore = useCurrentFriendChatStore()
            const userStore = useUserStore()
            const messagesStore = useMessagesStore();
            return {currentFriendChatStore, userStore, messagesStore}
        },
        methods: {
            addText(text){
                this.$emit('add-text-event', '[' + text + '] ');
            },
            convertTime(unix_timestamp){
                var date = new Date(unix_timestamp);

                // Hours part from the timestamp
                var hours = date.getHours();

                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();

                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                return formattedTime
            },
            computeUsername(id){
                if(id === this.userStore.user.id){
                    return this.userStore.user.name
                } else {
                    return this.currentFriendChatStore.friend.username
                }
            }
        },
        components: {
            PrivateMessage
        }
    }
</script>

<style>
.message-username {
    background-color: rgb(118, 208, 56);
    padding: 3px 10px;
    border-radius: 7px;
    font-weight: bold;
    color: white;
    margin-right: 5px;
    cursor: pointer;
}
.message-user-pfp {
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}
.message-username-info {
    background-color: rgb(161, 139, 255);
    color: rgb(255, 239, 255);
    border-radius: 7px;
    font-weight: bold;
    margin-right: 5px;
    padding: 3px 10px;
    opacity: 0.8;
    cursor: pointer;
}
.message-element {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.message-element p {
    margin: 0;
}
.message-entered {
    color:rgb(184, 107, 227);
    font-style: italic;
    font-weight: bold;
}
.message-exited {
    color: rgb(62, 202, 217);
    font-style: italic;
    font-weight: bold;
}
.message-dead {
    color: rgb(210, 118, 223);
}
.message-last {
    color: rgb(172, 172, 172);
}
.message-username-girl {
    background-color: #ff6cda;
}
.message-username-boy {
    background-color: rgb(62, 162, 255);
}
</style>