<template>
    <div class="sendmessage-features">
        <select name="features" id="features" v-model="feature">
            <option value="normal">-</option>
            <option value="base64">base64</option>
            <option value="pseudobar">pseudobar</option>
            <option value="toKana">kana</option>
            <option value="morse">morse</option>
            <option value="morseCyr">morse (cyr)</option>
        </select>
    </div>
</template>

<script>
    import * as wanakana from 'wanakana';
    import MorseCode from '@/morsecode'
    import { Base64 } from 'js-base64';

    const pseudobarSymbols = '#!%*&^?<~@>'
    export default {
        data() {
            return {
                feature: "",
            }
        },
        props: ['message'],
        methods: {
            applyFeature(text){
                console.log('apple')
                let outText
                switch(this.feature){
                    case 'base64':
                        try {
                            outText = Base64.encode(text)
                            //outText = Buffer.from(text, 'base64').toString("utf8")
                        } catch(e) {
                            // something failed

                            // if you want to be specific and only catch the error which means
                            // the base 64 was invalid, then check for 'e.code === 5'.
                            // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
                        }
                        break
                    case 'pseudobar':
                        outText = ''
                        for (let i = 0; i < text.length; i++) {
                            outText += pseudobarSymbols.charAt(Math.floor(Math.random() * pseudobarSymbols.length));
                        }
                        break
                    case 'toKana':
                        outText = wanakana.toKatakana(text)
                        {
                        const convertFullToHalf = outText => outText.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
                        
                        //outText = convertFullToHalf
                        }
                        break
                    case 'morse':
                        outText = MorseCode.encode(text)
                        break
                    case 'morseCyr':
                        outText = MorseCode.encodeCyr(text)
                        break
                    default:
                        outText = text
                }
                return outText
            },
            addText(text){
                this.$parent.$emit('add-text-event', '[' + text + '] ');
            },
        }
    }
</script>

<style>
.sendmessage-features {
    margin-right: 10px;
}
</style>