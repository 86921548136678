<template>
    <div class="messages-container">
        <ul>
            <li class="message-element" v-for="message in messagesStore.messages" :key="message.id">
                <Message v-bind:message=message></Message>
            </li>
        </ul>
    </div>
</template>

<script>
    import Message from './Message.vue'
    import { useMessagesStore } from '@/stores/MessagesStore';
    export default {
        setup(){
            const messagesStore = useMessagesStore();
            return {messagesStore};
        },
        methods: {
            addText(text){
                this.$emit('add-text-event', '[' + text + '] ');
            }
        },
        components: {
            Message
        }
    }
</script>

<style>

.message-element {
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-weight: 400;

    color: var(--message-fg);
}
.message-element p {
    margin: 0;
}

</style>