<template v-once>
    <div class="userinfo-container">
        <div class="userinfo-header" v-if="userinfo != null">
            <div class="userinfo-left">
                <img ref='userinfo_profileimg' @click="imageViewToggle" :class="fullImageToggle == false ? 'userinfo-pfp' : 'userinfo-pfp-full'" width="256px" height="256px" v-bind:src="'http://37.143.8.68/mafia/profile_photo/'+$route.params.id+'.jpg'">
                <button @click="openChat" class="userinfo-button blue-button">Chat</button>
                <button @click="friendButtonClicked" class="userinfo-button">{{this.friendButtonText}}</button>
            </div>
            <div v-if="userinfo!=null" class="userinfo-right">
                <div class="userinfo-right-left">
                    <span :class="userinfo.sex == '1' ? 'userinfo-username-girl' : 'userinfo-username-boy'" class="userinfo-username">{{ userinfo.username }}</span>
                    <span class="online-text" v-if="userinfo.online">online</span>
                </div>
                <section v-if="userinfo.roomID != null" class="userinfo-room">
                    {{ userinfo.roomTitle }}
                    {{ userinfo.roomPlayers }}
                    {{ userinfo.roomLevel }}
                    <button @click="enterRoom"></button>
                </section>
            </div>
        </div>
        <div class="userinfo-statistics" v-if="userinfo!=null">
            <div class="section-title">Played as</div>
            <section class="played-as-section">
                <ul>
                    <li class="userinfo-played-total">Total: {{userinfo.playedTotal}}</li>
                    <li>Mafia: {{userinfo.playedMafia}}</li>
                    <li>Sheriff: {{userinfo.playedSher}}</li>
                    <li>Doctor: {{userinfo.playedDoc}}</li>
                    <li>Lover: {{userinfo.playedLover}}</li>
                    <li>Terrorist: {{userinfo.playedTerr}}</li>
                    <li>Journalist: {{userinfo.playedJour}}</li>
                    <li>Bodyguard: {{userinfo.playedBody}}</li>
                    <li>Barmen: {{userinfo.playedBar}}</li>
                    <li>Spy: {{userinfo.playedSpy}}</li>
                    <li>Civilians: {{userinfo.playedCiv}}</li>
                    <li>Informer: {{userinfo.playedInf}}</li>
                </ul>
            </section>
            <div class="section-title">Wins as</div>
            <section class="played-as-section">
                <ul>
                    <li>Civilians: {{userinfo.winsCiv}}</li>
                    <li>Mafia: {{userinfo.winsMaf}}</li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
    import {ref} from 'vue'
    import router from './../router';
    import { useUserStore } from '@/stores/UserStore';
    const colorThief = require('colorthief');
    export default {
        data(){
            return {
                fullImageToggle: false,
            }
        },
        setup(){
            const userStore = useUserStore()
            return {userStore}
        },
        props: ['userinfo'],
        created() {
            this.$emit('send-message-event', JSON.stringify({"ty":"gup","uo":this.$route.params.id}));
        },
        methods: {
            imageViewToggle(){
                this.fullImageToggle = !this.fullImageToggle
            },
            friendButtonClicked(){
                switch(this.userinfo.friendStatus){
                    case 0:
                        // Add friend
                        this.$emit('send-message-event', JSON.stringify({"ty":"af","f":this.$route.params.id}));
                        break;
                    case 1:
                        // Cancel request
                        this.$emit('send-message-event', JSON.stringify({"ty":"rf","f":this.$route.params.id}));
                        break;
                    case 2:
                        this.$emit('send-message-event', JSON.stringify({"ty":"af","f":this.$route.params.id}));
                        break;
                    case 3:
                        // Remove friend
                        this.$emit('send-message-event', JSON.stringify({"ty":"rf","f":this.$route.params.id}));
                        break;
                }
                this.$emit('send-message-event', JSON.stringify({"ty":"af","f":this.$route.params.id}));
            },
            openChat(){
                this.$emit('send-message-event', JSON.stringify({"ty":"acpc", "t":this.userStore.user.token, "uo":this.userStore.user.id, "fp":this.userinfo.friendID}));
                router.push({ name: 'private', params: { id: this.userinfo.friendID } })
            },
            enterRoom(){
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"re","ro":this.userinfo.roomID}))
            },
            getDominantColor(){
                /*
                if (this.$refs.userinfo_profileimg.$el.complete) {
                    colorThief.getColor(this.$refs.userinfo_profileimg.$el);
                    } else {
                    image.addEventListener('load', function() {
                        colorThief.getColor(this.$refs.userinfo_profileimg.$el);
                    });
                }*/
            },
        },
        computed: {
            friendButtonText(){
                let m_friendButtonText = ""
                switch(this.userinfo.friendStatus){
                    case 0:
                        m_friendButtonText = "Add Friend"
                        break;
                    case 1:
                        m_friendButtonText = "Request was sent"
                        break;
                    case 2:
                        m_friendButtonText = "Accept friendship"
                        break;
                    case 3:
                        m_friendButtonText = "Delete from friends"
                        break;
                }
                return m_friendButtonText
            }
        },
        beforeMount() {
            this.getDominantColor()
        },
    }
</script>

<style>
.userinfo-room {
}

.userinfo-header {
    display: flex;
    margin-right: 40px;
}
.userinfo-button {
    margin: 3px 0;
}
.userinfo-left {
    display: flex;
    flex-direction: column;
}
.userinfo-pfp {
    border-radius: 50%;
    margin: 0 30px 30px 0;
    object-fit: cover;
    cursor: pointer;
}
.userinfo-pfp-full {
    margin: 0 30px 30px 0;
    height: auto;
    cursor: pointer;
}
.userinfo-container {
    display: flex;
}
.userinfo-username-girl {
    background-color: #ff6cda;
    box-shadow: 0 0 14px #fff;
}
.userinfo-username-boy {
    background-color: rgb(62, 162, 255);
    box-shadow: 0 0 14px #fff;
}
.userinfo-username {
    padding: 4px 9px;
    border-radius: 7px;
    font-weight: bold;
    margin-bottom: 5px;
}

.userinfo-statistics {
    background-color: #16c1e8bf;
    color: #fff;
    padding: 14px 20px;
    border-radius: 6px;
    box-shadow: 0 0 14px #fff;
    align-self: baseline;
    position: relative
}
/* Pre-render the bigger shadow, but hide it */
.userinfo-statistics::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 0 30px 19px #a9e7fac0;
    opacity: 0;
    transition: opacity 7s ease-out 2s;
}
.userinfo-statistics:hover::after {
    opacity: 1;
}
.userinfo-played-total {
    margin-bottom: 10px;
    font-style: italic;
    text-decoration: underline;
}
.userinfo-right-left {
    display: flex;
    flex-direction: column;
}
</style>