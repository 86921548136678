<template>
    <div>
        Settings
        <label for="themes">Choose a theme:</label>
        <select name="themes" id="themes" v-model="theme" @change="onChangeTheme($event)">
            <option value="frutiger_aero">Frutiger Aero</option>
            <option value="club">Club</option>
        </select>
        <span>Selected theme: {{ theme }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            theme: "",
        }
    },
    methods: {
        onChangeTheme(event){
            console.log(event.target.value)
            switch(event.target.value){
                case 'frutiger_aero':
                    this.$parent.$emit('set-theme-event', 0)
                    break
                case 'club':
                    this.$parent.$emit('set-theme-event', 1)
                    break
            }

        }
    }
}
</script>