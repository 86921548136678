<template>
    <header class="header">
      <Navbar></Navbar>
      <h1 class="title">マフィア</h1>
    </header>
    <div class="content">
      <RouterView 
        :rooms="m_rooms"
        @send-message-event="sendMessage"
        :userSearchResult="m_userSearchResult"
        :userinfo="m_userinfo"
        :timer="m_timer"
        :friends="m_friends"
        :dashboardUser="m_dashboardUser"
        @set-theme-event="setTheme"
        >
      </RouterView>
    </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import router from './router';

import { useUserStore } from './stores/UserStore';
import { useRoomStore } from './stores/RoomStore';
import { usePlayersStore } from './stores/PlayersStore';
import { usePlayersRoomStore } from './stores/PlayersRoomStore';

import { ref } from 'vue'
import { useMessagesStore } from './stores/MessagesStore';

const ROLE_CIV = 		"1"
const ROLE_DOC = 		"2"
const ROLE_COMISSAR = 		"3"
const ROLE_MAFIA = 		"4"
const ROLE_LOVER = 		"5"
const ROLE_TERRORIST = 		"6"
const ROLE_JOURNALIST = 		"7"
const ROLE_BODYGUARD = 		"8"
const ROLE_BARMAN = 		"9"
const ROLE_SPY = 		"10"
const ROLE_INFORMER = 		"11"

const keys = {
	Rooms: "rs" ,
	NotSigned: "siner" ,
	SignIn:"usi" ,
	Messages:"ms" ,
	Message:"m",
	PlayersNum:"rils" ,
	AddRoom:"add" ,
	RoomGameStatus:"gsrl" ,
	DeleteRoom:"rm" ,
	NewUsers:"u" ,
	RoomIsFull:"mxmp" ,
	UserLevelIsNotEnough:"ulne" ,
	GameHasStarted:"gsd" ,
	RoomWrongPassword:"rpiw" ,
	EnterRoom:"re" ,
	PlayersList:"pls" ,
	GameStatus:	"gs" ,
	Dashboard:"uud" ,
	UserInAnotherRoom:"uiar" ,
	AddPlayer:"ap" ,
	RoomKey:"rr" ,
	Roles:"roles" ,
	RoomStat:"ps" ,
	DeletePlayer:"rp" ,
	DayTime:"gd" ,
	UserData:"ud" ,
	GameFinished:"gf" ,
	RoomCreated:"rcd" ,
	KickUser:"ku" ,
	DeleteChatUser:"rmm" ,
	FriendsList:"frl" ,
	PrivateMsg:"pclm" ,
	PrivateMsgs:"pclms" ,
	CreatingRoomBlocked:"crb" ,
	SearchUsers:"su" ,
	UserInfo:"uup" ,
	AddFriend:"af" ,
	RemoveFriend:"rf" ,
	PN:"pn" ,
	UserBanned:"ublk" ,
	KickMinXP:"kur" ,
	KickTimer:"kt" ,
	KickGameStarted:"kugs" ,
	UsernameIsntSet:"ueue" ,
	ChangeNickMinXP:"neae" ,
	NicknameWasChanged:"uns" ,
	NicknameLength:"unob" ,
	NicknameChars:"unws" ,
	UserRoleError:"ure" ,
};

export default {
  name: 'App',
  components: {
    Navbar,
  },
  setup(){
    const userStore = useUserStore()
    const roomStore = useRoomStore()
    const playersStore = usePlayersStore()
    const playersRoomStore = usePlayersRoomStore()
    const messagesStore = useMessagesStore()
    return {userStore, roomStore, playersStore, playersRoomStore, messagesStore}
  },
  beforeMount(){
    this.setTheme(0)
  },
  data() {
    return {
      connection: null,
      connectionEstablished: false,
      logined: false,
      activePage: 0,
      m_theme_idx: 0,
      
      m_user: null,
      m_rooms: [],
      m_friends: [],
      m_userSearchResult: [],
      m_timer: -1,
      m_currentFriend: null,
      m_dashboardUser: null,

      is_in_room: false,

      m_userinfo: null,

      themes: [
        {
          c_bg_img_url: 'FAwallpaper2-fixed.png',

          c_playername_color: "rgb(255, 255, 255)",

          c_title_fg: "rgb(166, 243, 255)",
          c_title_shadow: "2px 2px 2px #559397",

          c_input_fg: "rgb(0, 0, 0)",
          c_input_bg: "rgb(255, 255, 255)",

          c_button_bg: "rgb(245, 245, 245)",
          c_button_bg_hover: "rgb(198, 170, 204)",
          c_button_fg: "rgb(37, 37, 37)",

          c_nav_bg: "rgba(255, 255, 255, 0.7)",
          c_nav_button_bg: "rgb(245, 245, 245)",
          c_nav_button_bg_hover: "rgb(198, 170, 204)",
          c_nav_button_fg: "rgb(37, 37, 37)",

          c_chat_bg: "rgba(246, 253, 250, 0.8)",

          c_message_fg: "rgb(0, 0, 0)",
        },
        {
          c_bg_img_url: 'FA_wallpaper5_dark.png',
          //c_bg_img_url: "url('@/assets/bg/purple.png')",

          c_playername_color: "rgb(255, 255, 255)",

          c_title_fg: "rgb(166, 243, 255)",
          c_title_shadow: "2px 2px 2px #559397",

          c_input_fg: "rgb(255, 255, 255)",
          c_input_bg: "rgb(66, 23, 49)",

          c_button_bg: "rgb(245, 245, 245)",
          c_button_bg_hover: "rgb(198, 170, 204)",
          c_button_fg: "rgb(37, 37, 37)",

          c_nav_bg: "rgba(223, 168, 217, 0.25)",
          c_nav_button_bg: "rgba(255, 240, 251, 0.88)",
          c_nav_button_bg_hover: "rgb(198, 170, 204)",
          c_nav_button_fg: "rgb(49, 49, 49)",

          c_chat_bg: "rgba(255, 255, 255, 0.39)",

          c_message_fg: "rgb(255, 255, 255)",

        },
      ]
    }
  },
  created(){
    console.log("Starting connection with mafia server")
    this.connection = new WebSocket("ws://37.143.8.68:7090");

    this.connection.onopen = (event) => {
        console.log(event)
        console.log("Successfully connected")
        this.connectionEstablished = true
    }

    this.connection.onmessage = (event) => {
        console.log("Recieved message from websocket", event)
        if(event.data == 'p' || event.data == ''){
          return;
        }
        try {
          const json_data = JSON.parse(event.data);
          switch(json_data.ty){
            case keys.SignIn:
              this.m_user = this.deserializeUserInfo(json_data, true)
              this.logined = true
              router.push({ path: '/' })
              this.sendMessage(JSON.stringify({"t":this.m_user.token,"ty":"acd","uo":this.m_user.id}));
              break
            case keys.Rooms:
              this.m_rooms = []
              json_data.rs.forEach(element => {
                this.m_rooms.push(this.deserializeRoomInList(element))
              })
              this.m_rooms.sort((roomA, roomB) => {
                let ratioA = (roomA.pn / roomA.mnp) * 100;
                let ratioB = (roomB.pn / roomB.mnp) * 100;

                // Cap the ratios at 100
                if (ratioA > 100) ratioA = 100;
                if (ratioB > 100) ratioB = 100;

                // Initialize comparison variables
                let compareA = 0;
                let compareB = 0;

                // Compare ratios
                if (ratioA > ratioB) {
                    compareA += 1;
                } else if (ratioA < ratioB) {
                    compareB += 1;
                }

                // Compare game status
                const statusValue = status => (status === 0 ? 1 : (status === 1 ? 2 : 0));
                const statusA = statusValue(roomA.rs);
                const statusB = statusValue(roomB.rs);

                if (statusA > statusB) {
                    compareA += 2;
                } else if (statusA < statusB) {
                    compareB += 2;
                }

                // Compare current room status
                /*
                if (roomA.isCurrentRoom) {
                    compareA += 3;
                }
                if (roomB.isCurrentRoom) {
                    compareB += 3;
                }
                */
                // Final comparison
                /*
                if (compareA > compareB) {
                    return -1;
                } else if (compareA < compareB) {
                    return 1;
                } else {
                    return 0;
                }
                */
                if (roomA.pn > roomB.pn){
                  return -1
                } else if (roomA.pn < roomB.pn){
                  return 1
                } else {
                  return 0
                }
            });


              break
            case keys.SearchUsers:
              this.m_userSearchResult = []
              json_data.u.forEach(element => {
                this.m_userSearchResult.push(element)
              })
              break
            case keys.PlayersNum:
              {
                const room_id = json_data.rils.ro
                this.m_rooms.find((o, i) => {
                  if (o.id === room_id) {
                    this.m_rooms[i].players = json_data.rils.pin
                  }
                })
              }
              break
            case keys.RoomGameStatus:
              {
                const room_id = json_data.ro
                this.m_rooms.find((o, i) => {
                  if (o.id === room_id) {
                    this.m_rooms[i].status = json_data.s
                  }
                })
              }
              break
            case keys.Messages:
              this.messagesStore.messages = []
              if(this.is_in_room){
                json_data.m.forEach(element => {
                  this.messagesStore.messages.push(this.deserializeMessage(element))
                })
              }
              else {
                json_data.ms.forEach(element => {
                  this.messagesStore.messages.push(this.deserializeMessage(element))
                })
              }
              break
            case keys.DeleteRoom:
              {
                const room_id = json_data.ro
                this.m_rooms.find((o, i) => {
                  if (o.id === room_id) {
                    this.m_rooms.splice(i, 1);
                  }
                })
              }
              break
            case keys.AddRoom:
              this.m_rooms.push(this.deserializeRoom(json_data.rr))
              break
            case keys.Message:
              this.messagesStore.messages.push(this.deserializeMessage(json_data.m))
              break
            case keys.NewUsers:
              this.playersStore.players = []
              json_data.u.forEach(element => {
                this.playersStore.players.push(this.deserializePlayer(element))
              })
              break
            case keys.EnterRoom:
            case keys.RoomCreated:
              this.roomStore.room = this.deserializeLobbyRoom(json_data.rr)
              console.log("ROMSHIT", this.roomStore.room)
              router.push('/game')
              this.is_in_room = true
              this.sendMessage(JSON.stringify({"t":this.m_user.token,"ty":"cp","uo":this.m_user.id,"ro":this.roomStore.room.id}))
              break
            case keys.PlayersList:
              console.log("FAKO list")
              this.playersRoomStore.players = []
              json_data.pls.forEach(element => {
                this.playersRoomStore.players.push(this.deserializePlayer(element.uu))
              })
              break
            case keys.AddPlayer:
            console.log("FAKO add")
              this.playersRoomStore.players.push(this.deserializePlayer(json_data.p.uu))
              break
            case keys.DeletePlayer:
            console.log("FAKO del")
              {
                const user_id = json_data.uo
                this.playersRoomStore.players.find((o, i) => {
                  if (o.id === user_id) {
                    this.playersRoomStore.players.splice(i, 1);                    
                  }
                })
              }
              break
            case keys.UserInfo:
              this.m_userinfo = this.deserializeUserGetInfo(json_data);
              console.log('GOT USER INFO', this.m_userinfo)
              break
            case keys.FriendsList:
              this.m_friends = []
              json_data.frl.forEach(element => {
                this.m_friends.push(this.deserializeFriendInList(element))
              })
              break
            case keys.PrivateMsgs:
              this.messagesStore.messages = []
              json_data.ms.forEach(element => {
                this.messagesStore.messages.push(this.deserializePrivateMessage(element))
              })
              break
            case keys.PrivateMsg:
              this.messagesStore.messages.push(this.deserializePrivateMessage(json_data.m))
              break
            case keys.Dashboard:
              this.m_dashboardUser = this.deserializeDashboardUserInfo(json_data)
              this.userStore.user = this.m_dashboardUser
              break
            case keys.UserData:
              json_data.data.forEach(element => {
                const foundIndex = this.playersRoomStore.players.findIndex(obj => {
                  return obj.id === element.uo
                })
                console.log('BEFORE', this.playersRoomStore.players[foundIndex].data)
                Object.assign(this.playersRoomStore.players[foundIndex].data, this.deserializePlayerData(element))
                console.log('AFTER', this.playersRoomStore.players[foundIndex].data)
              })
              break
            case keys.RoomStat:
              //{"c":4,"ma":8,"ty":"ps","m":4,"ca":13}
              this.roomStore.civ_cur = json_data.c
              this.roomStore.civ_max = json_data.ca
              this.roomStore.maf_cur = json_data.m
              this.roomStore.maf_max = json_data.ma
              break
          }
          if("t" in json_data){
            this.m_timer = json_data.t;
          }
        } catch(e){
          console.log("Error transcribing JSON", e)
        }
    }
    // eslint-disable-next-line no-unused-vars
    router.afterEach((to, from) => {
      console.log(to.name)
      if(to.name == 'game'){
        this.is_in_room = true
      } else {
        this.is_in_room = false

        if(to.name == 'chat' || to.name == 'private'){
          this.messages = []
          console.log('clear messages')
        }
      }
    })
  },

  methods: {
    sendMessage(message) {
      if(this.connectionEstablished){
        console.log(message)
        this.connection.send(message)
      }
    },

    setTheme(theme_idx){
      this.m_theme_idx = theme_idx;

      const body = document.body;
      body.style.background = `url(${require(`../public/backgrounds/${this.themes[theme_idx].c_bg_img_url}`)})`;
      body.style.backgroundAttachment = 'fixed';
      body.style.backgroundRepeat = 'no-repeat';
      body.style.backgroundSize = 'cover';

      document.documentElement.style.setProperty("--playername-color", this.themes[theme_idx].c_playername_color);
      document.documentElement.style.setProperty("--title-fg", this.themes[theme_idx].c_title_fg);
      document.documentElement.style.setProperty("--title-shadow", this.themes[theme_idx].c_title_shadow);
      document.documentElement.style.setProperty("--input-fg", this.themes[theme_idx].c_input_fg);
      document.documentElement.style.setProperty("--input-bg", this.themes[theme_idx].c_input_bg);
      document.documentElement.style.setProperty("--button-bg", this.themes[theme_idx].c_button_bg);
      document.documentElement.style.setProperty("--button-bg-hover", this.themes[theme_idx].c_button_bg_hover);
      document.documentElement.style.setProperty("--nav-fg", this.themes[theme_idx].c_nav_fg);
      document.documentElement.style.setProperty("--nav-button-bg", this.themes[theme_idx].c_nav_button_bg);
      document.documentElement.style.setProperty("--nav-button-bg-hover", this.themes[theme_idx].c_nav_button_bg_hover);
      document.documentElement.style.setProperty("--nav-button-fg", this.themes[theme_idx].c_nav_button_fg);
      document.documentElement.style.setProperty("--chat-bg", this.themes[theme_idx].c_chat_bg);
      document.documentElement.style.setProperty("--message-fg", this.themes[theme_idx].c_message_fg);
      //document.documentElement.style.setProperty("--bg-img-url", this.themes[theme_idx].c_bg_img_url);
    },

    deserializeUserInfo(data, isPlayerInfo=false){
      let userInfo;
      if(!isPlayerInfo){
        userInfo = {
          'wins_civ': data['uu']['wip'],
          'wins_maf': data['uu']['wim'],
      
          'as_bar': data['uu']['prst'][ROLE_BARMAN],
          'as_bd': data['uu']['prst'][ROLE_BODYGUARD],
          'as_sher': data['uu']['prst'][ROLE_COMISSAR],
          'as_doc': data['uu']['prst'][ROLE_DOC],
          'as_jour': data['uu']['prst'][ROLE_JOURNALIST],
          'as_maf': data['uu']['prst'][ROLE_MAFIA],
          'as_civ': data['uu']['prst'][ROLE_CIV],
          'as_lov': data['uu']['prst'][ROLE_LOVER],
          'as_spy': data['uu']['prst'][ROLE_SPY],
          'as_ter': data['uu']['prst'][ROLE_TERRORIST],
          'as_inf': data['uu']['prst'][ROLE_INFORMER],
        
          'id': data['uu']['o'],
          'name': data['uu']['u'],

          'authority': data['uu']['a']
        };
      } else {
        userInfo = {
          'wins_civ': data['uu']['wip'],
          'wins_maf': data['uu']['wim'],
      
          'as_bar': data['uu']['prst'][ROLE_BARMAN],
          'as_bd': data['uu']['prst'][ROLE_BODYGUARD],
          'as_sher': data['uu']['prst'][ROLE_COMISSAR],
          'as_doc': data['uu']['prst'][ROLE_DOC],
          'as_jour': data['uu']['prst'][ROLE_JOURNALIST],
          'as_maf': data['uu']['prst'][ROLE_MAFIA],
          'as_civ': data['uu']['prst'][ROLE_CIV],
          'as_lov': data['uu']['prst'][ROLE_LOVER],
          'as_spy': data['uu']['prst'][ROLE_SPY],
          'as_ter': data['uu']['prst'][ROLE_TERRORIST],
          'as_inf': data['uu']['prst'][ROLE_INFORMER],
        
          'token': data['uu']['t'],
          'id': data['uu']['o'],
          'name': data['uu']['u'],
        };
      }
     return userInfo;
    },

    deserializeRoomInList(room){
      return {'title':room.tt,'players':room.pn,'min_players':room.mnp,'max_players':room.mxp,'status':room.s,'room_status':room.rs,'id':room.o};
    },
    deserializeRoom(room){
      return {'title':room.tt,'min_players':room.mnp,'max_players':room.mxp,'id':room.o,'status':room.s};
    },
    deserializeMessage(message){
      let out_message = {'text':message.tx, 'type':message.t};
      if (message.uu != null){
        out_message.username = message.uu.u
        out_message.userid = message.uu.o
        out_message.sex = message.uu.s
      }
      if (message.c != null){
        out_message.time = message.c
      }
      return out_message
    },
    deserializePrivateMessage(message){
      return {'text':message.tx, 'type':message.t, 'userid': message.uo, 'time':message.c}
    },
    deserializePlayer(player){
      return {'name':player.u,'sex':player.s,'id':player.o,'online':player.on, 'data':{}}
    },
    deserializeLobbyRoom(room){
      return{
        'id':room.o, 
        'status':room.s, 
        'title':room.tt,
        'min_players':room.mnp,
        'max_players':room.mxp,
        'level':room.mnl,
        'vip':room.venb,
        'password':room.pw,
        'is_doc':room.sr.includes(parseInt(ROLE_DOC)),
        'is_lv':room.sr.includes(parseInt(ROLE_LOVER)),
        'is_terr':room.sr.includes(parseInt(ROLE_TERRORIST)),
        'is_jur':room.sr.includes(parseInt(ROLE_JOURNALIST)),
        'is_body':room.sr.includes(parseInt(ROLE_BODYGUARD)),
        'is_bar':room.sr.includes(parseInt(ROLE_BARMAN)),
        'is_spy':room.sr.includes(parseInt(ROLE_SPY)),
        'is_inf':room.sr.includes(parseInt(ROLE_INFORMER)),
        't':'t' in room ? room.t : -1,
      }
    },
    deserializeUserGetInfo(userinfo){
      let uif = {
        'id':userinfo.uu.o,
        'username':userinfo.uu.u,
        'serverLanguage':userinfo.uu.slc,
        'online':userinfo.uu.on,
        'sex':userinfo.uu.s,
        'hasPhoto':userinfo.uu.ph,

        'playedTotal':userinfo.uu.pg,
        'playedMafia':userinfo.uu.prst[ROLE_MAFIA],
        'playedSher':userinfo.uu.prst[ROLE_COMISSAR],
        'playedDoc':userinfo.uu.prst[ROLE_DOC],
        'playedLover':userinfo.uu.prst[ROLE_LOVER],
        'playedTerr':userinfo.uu.prst[ROLE_TERRORIST],
        'playedJour':userinfo.uu.prst[ROLE_JOURNALIST],
        'playedBody':userinfo.uu.prst[ROLE_BODYGUARD],
        'playedBar':userinfo.uu.prst[ROLE_BARMAN],
        'playedSpy':userinfo.uu.prst[ROLE_SPY],
        'playedCiv':userinfo.uu.prst[ROLE_CIV],
        'playedInf':userinfo.uu.prst[ROLE_INFORMER],

        'winsMaf':userinfo.uu.wim,
        'winsCiv':userinfo.uu.wip,

        'lastAuthorized':userinfo.uu.up,
        'friendStatus':userinfo.fpf,
        'friendID':userinfo.fp,
      }
      if('rr' in userinfo){
        uif.roomID = userinfo.rr.o
        uif.roomVIP = userinfo.rr.venb
        uif.roomLevel = userinfo.rr.mnl
        uif.roomStatus = userinfo.rr.s
        uif.roomTitle = userinfo.rr.tt
        uif.roomPassword = userinfo.rr.pw
        uif.roomPlayers = userinfo.rr.pn
      }

      return uif
    },
    deserializeFriendInList(el){
      let friend_dict = {
          'friend_id':el.o,
          'friend_up':el.up,
          'new_messages':el.nm}
        let key = 'uu'
        if (!(key in el)){
          key = 'ff'
        }
        friend_dict.user_id = el[key].o
        friend_dict.user_up = el[key].up
        friend_dict.username = el[key].u
        friend_dict.sex = el[key].s
        friend_dict.online = el[key].on
        friend_dict.has_photo = el[key].ph
        if ('rr' in el){
          friend_dict.room = {'id':el.rr.o, 'status':el.rr.s}
        }
        return friend_dict
      },
      deserializeDashboardUserInfo(data){
        return {
          'wins_civ': data['uud']['wip'],
          'wins_maf': data['uud']['wim'],
      
          'played_total': data['uud']['pg'],
          'as_bar': data['uud']['prst'][ROLE_BARMAN],
          'as_bd': data['uud']['prst'][ROLE_BODYGUARD],
          'as_sher': data['uud']['prst'][ROLE_COMISSAR],
          'as_doc': data['uud']['prst'][ROLE_DOC],
          'as_jour': data['uud']['prst'][ROLE_JOURNALIST],
          'as_maf': data['uud']['prst'][ROLE_MAFIA],
          'as_civ': data['uud']['prst'][ROLE_CIV],
          'as_lov': data['uud']['prst'][ROLE_LOVER],
          'as_spy': data['uud']['prst'][ROLE_SPY],
          'as_ter': data['uud']['prst'][ROLE_TERRORIST],
          'as_inf': data['uud']['prst'][ROLE_INFORMER],
        
          'sex': data['uud']['s'],
          'token': data['uud']['t'],
          'id': data['uud']['o'],
          'name': data['uud']['u'],
          'new_messages':data['nm'],
          'new_friends':data['fr'],
        }
      },
      deserializePlayerData(player){
        return {
          'alive':player.a,
          'role':player.r,
          'id':player.uo,
          'votes':player.v,
          'abr':player.abr,
          'inaa':player.inaa,
          'inau':player.inau,
          'idau':player.idau,
        }
      },
   },
}
</script>

<style>
:root {
  --playername-color: rgb(255, 255, 255);

  --title-fg: rgb(166, 243, 255);
  --title-shadow: 2px 2px 2px #559397;

  --input-fg: rgb(0, 0, 0);
  --input-bg: rgb(255, 255, 255);

  --button-bg: rgb(245, 245, 245);
  --button-bg-hover: rgb(198, 170, 204);
  --button-fg: rgb(37, 37, 37);

  --nav-bg: rgba(255, 255, 255, 0.7);
  --nav-button-bg: rgb(245, 245, 245);
  --nav-button-bg-hover: rgb(198, 170, 204);
  --nav-button-fg: rgb(37, 37, 37);

  --chat-bg: rgba(246, 253, 250, 0.8);

  --message-fg: rgb(0, 0, 0);
  --bg-img-url: 'url("https://i.redd.it/over-50-frutiger-aero-wallpapers-2008ify-your-pc-v0-9ckfyod2nz9b1.jpg?width=1920&format=pjpg&auto=webp&s=524b0ba7f8e41efb23d4f2f2149b43502851d746")';
}

li {
  list-style-type: none;
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 104px);
  margin: 0 15px;
  padding: 20px 0 0 0;  
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--nav-bg);
  align-items: center;
}
.title {
  margin: 0 25px 0 0;
  font-size: 40px;
  color: var(--title-fg);
  text-shadow: var(--title-shadow);
  font-style: italic;
  transition: text-shadow 0.2s ease-out;
}
.title:hover {
  text-shadow: 0px 0px 10px #7bd4ff;
}

button {
  background-color: var(--button-bg);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: var(--button-fg);
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border: 1px solid #7594ee;

  box-shadow: rgba(165, 207, 219, 0.7) 1px 1px 20px 0px inset;
  transition: box-shadow 0.2s ease-out;
}
button:hover {
  box-shadow: rgba(8, 125, 221, 0.7) 1px 1px 30px 0px inset;
}

input {
  padding: 10px 6px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;

  background-color: var(--input-bg);
  color: var(--input-fg);
}

.blue-button {
  background-color: #78ddf4;
  
  box-shadow: rgba(241, 252, 255, 0.75) 1px 1px 20px 0px inset;
}

.blue-button:hover {
  background-color: #97efff;
}

body, html{
  padding: 0;
  margin: 0;
  font-family: 'sans-serif';

  color: white;
}
body {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

video {
  margin-top: -20px;
  margin-bottom: -20px;
}
</style>
