<template>
        <button @click="enterRoom" :class="applyRoomClass">
            <div class="room-button-content">
                <div class="room-button-properties">
                </div>
                <div class="room-button-title">
                    {{ room.title }} 
                </div>
                <div class="room-button-players">
                    <span>
                        {{ room.min_players }}/{{ room.max_players }}
                    </span>
                    <span>
                        {{ room.players }}
                    </span>
                </div>
            </div>
            <button class="room-button-info">i</button>
        </button>
</template>

<script>
    export default {
        props: ['room'],
        methods: {
            enterRoom(){
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"re","ro":this.room.id}))
                console.log(this.room.id)
            }
        },
        computed: {
            applyRoomClass(){
                let m_class = 'room-button ';
                switch(this.room.status){
                    case 0:
                        break;
                    case 1:
                        m_class += 'room-button-timer'
                        break;
                    case 2:
                        m_class += 'room-button-started '
                        break;
                }
                switch(this.room.room_status){
                    case 0:
                        m_class += 'room-button-playerin'
                        break;
                    case 1:
                        m_class += 'room-button-died'
                        break;
                }
                return m_class
            }
        }
    }
</script>

<style>
.room-button {
    height: 70px;
    width: 300px;
    margin: 3px 0;
    position: relative;
}
.room-button-timer {
    background-color: orange
}
.room-button-playerin {
    background-color: rgb(8, 230, 0)
}
.room-button-died {
    background-color: rgb(255, 38, 0)
}
.room-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.room-button-players {
    display: flex;
    flex-direction: column;
}
.room-button-info {
    position: absolute;
    right: -20px;
    top: -20px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background-color: #b9fff8;
    padding: 0;
}
</style>