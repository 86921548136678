import { createWebHistory, createRouter } from 'vue-router'

import RoomList from './components/RoomList.vue'
import UsersSearch from './components/UsersSearch.vue'
import Home from './components/Home.vue'
import PublicChat from './components/PublicChat.vue'
import RoomLobby from './components/RoomLobby.vue'
import UserInfo from './components/UserInfo.vue'
import Login from './components/Login.vue'
import FriendsList from './components/FriendsList.vue'
import PrivateChat from './components/PrivateChat.vue'
import Settings from './components/Settings.vue'

const routes = [
  { path: '/', name: 'home', component: Home},
  { path: '/login', name: 'login', component: Login},
  { path: '/chat', name: 'chat', component: PublicChat, props: {'players': []}},
  { path: '/rooms', name: 'rooms', component: RoomList, props: {'rooms': []} },
  { path: '/users', name: 'users', component: UsersSearch, props: {'userSearchResult': []} },
  { path: '/friends', name: 'friends', component: FriendsList, props: {'friends': []} },
  { path: '/private/:id', name: 'private', component: PrivateChat },
  { path: '/game', name: 'game', component: RoomLobby, props: {'players': [], timer: -1} },
  { path: '/user/:id', name: 'user', component: UserInfo, props: {'userinfo': null}},
  { path: '/settings', name: 'settings', component: Settings}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router